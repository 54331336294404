@import '~normalize.css';

body {
  overflow: auto;
}

#root {
  //height: 100vh;
  position: relative;
  //width: 100vw;
}

.ant-menu{
  color: #474747!important;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-submenu-title:hover,
.ant-menu-submenu-selected,
.ant-menu-item-selected {
  color: #6c0a75!important;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #fdf2ff !important;
}
.ant-menu-item:active, .ant-menu-submenu-title:active {
  background-color: #fdf2ff !important;
}

.ant-menu-inline .ant-menu-item::after {
  border-right: 3px solid #6c0a75 !important;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item {
  padding-left: 38px !important;
  font-size: 12px !important;
}

.horizontal {
  flex-direction: unset !important;
}
.horizontal > .ant-form-item-label {
  padding: 0!important;
  display: flex !important;
  justify-content: flex-end!important;
  align-items: center!important;
}

.horizontal > .ant-form-item-label:after{
  content: ":";
  position: relative;
  top: -0.5px;
  margin: 0 8px 0 2px;
}

.required > .ant-form-item-label > label::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.menu-item-button {
  padding: 0 !important;
  text-align: left !important;
  background: transparent !important;
}

.breadcrumb-link {
  padding: 0 !important;
}

@primary-color: #0c7bbb;@primary-color-light: #50a1d0;