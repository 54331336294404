.loading__wrapper {
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all var(--transition-delay) var(--transition-timing-function);
  z-index: 1001;

  &.fullscreen {
    height: 100vh;
    position: fixed;
    width: 100vw;
  }

  .tip {
    color: @primary-color;
  }
}
@primary-color: #0c7bbb;@primary-color-light: #50a1d0;